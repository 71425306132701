:root
  --primary-color: #263141
  --color-primary: #263141
  --primary-r: 38
  --primary-g: 49
  --primary-b: 65
  --primary-h: 216
  --primary-s: 26.2%
  --primary-l: 20.2%
  --switch: 100%
:root
  --secondary-color: #6C8BB8
  --color-secondary: #6C8BB8
